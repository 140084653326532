$primary-color: #0067ac;
$secondary-color: #de466d;
$text-danger: #cc4d4d;
$semi-primary: #4eabe1;
$text-info: #646e76;
$light-info: #8b9daa;
$text-light: #ffffff;
$semi-light: #fafafa;
$semi-info: #e9edf2;
$dark: #000;
$text-gray:#646E76;
$text-light-gray: #8B9DAA;
$light-grey:#5A607F;
// font-size
$font-8: 8px;
$font-9: 9px;
$font-10: 10px;
$font-11: 11px;
$font-12: 12px;
$font-13: 13px;
$font-15: 15px;
$font-16: 16px;


//toaster color-------
$warning-bg-color : #d4b350;
$success-bg-color:#28a745;
$error-bg-color: #dc3545;


// Custom GER variables
$masters-color-blue : #42973a;
$theme-blue : #42973a;
$theme-gray: #a1a1a1;
$theme-gray-light: #EFEFEF;
$theme-gray-dark: #707070;
$theme-red : #EF6A65;
$font-roboto: Roboto, sans-serif;
$table-data-color: #3f414c;
$search-color:#a0a0a0;
$border-radius-1: 0.25rem;
$border-radius-2: 0.50rem;

$theme-table:#707070;
$theme-light-blue: #5377B5;
$radio-green: #C3DA8C;
$theme-font-size:11px;

$theme-blue: #1d2a5a;
$theme-green: #0e9046;
$theme-light-green: #47df89;
$blue-color: #0058ff;


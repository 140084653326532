/* You can add global styles to this file, and also import other style files */
@import "./assets/scss/app.scss";
@import "./assets/variable.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap");
$font-Poppins: "Poppins";
*{
  font-family: $font-Poppins;

}
.page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-content li .sidebar-submenu li a.active,
.page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-content li .sidebar-link.active span,
.page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-content li .sidebar-submenu li:hover > a,
.page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-content li:hover .sidebar-link:not(.active):hover span {
  color: #0058FF !important;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu > li a {
  padding-left: 3.8rem !important;
  &::before {
    background-color: transparent !important;
  }
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a span {
  color: #171725 !important;
}
.router-wrapper  {
    position:  relative;
    overflow:  hidden;
    width:  100vw;
    height:  calc(100%  -  47px);
    perspective:  1200px;
    transform-style:  preserve-3d;
}

:host  {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #60d7a9;
}

 //***custom ngx datatable component***
 .ngxdt {
  display: flex;
  flex-direction: column;
  margin-top: 0.75rem;

  >* {
    font-family:$font-Poppins, $font-roboto;
  }

  .ngxdt-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    row-gap: 45px;
    
    .ngxdt-buttons {
      align-self: center !important;

      button {
        color: #fff !important;
        border-radius: 0.25rem !important;
        padding: 0.25rem 1rem !important;
        border: none !important;
        display: flex !important;
        justify-content: center !important;
        align-content: center !important;
        font-size: $theme-font-size !important;

        svg {
          vertical-align: middle !important;
          align-self: center !important;
          margin-right: 0.25rem !important;
        }

        span {
          font-weight: 600;
          font-family: $font-Poppins,"Roboto";
        }

        &.btn-add {
          background-color: $theme-blue;
          line-height: 1.6;

          .fa {
            line-height: 1.5;
            height: 16px;
          }
        }
      }
    }

    .ngxdt-search {
      .ngxdt-search-wrapper {
        width: 300px;
        display: inline-flex;
        outline: 1px solid $search-color;
        margin: 0 1rem;
        align-items: center;
        height: calc(100% - 2px);
        padding: 0;
        border-radius: $border-radius-1;
        font-size: 0.75rem;

        i {
          padding: 0 10px;
          color: $search-color;
          flex: 0 0 auto;
        }

        input {
          border: none;
          height: calc(100% - 4px);
          margin: 0;
          padding: 0;
          color: $search-color;
          flex: 1 1 auto;
          width: max-content;
        }

        button {
          margin: 0;
          padding: 0.25rem 0;
          background-color: transparent;
          outline: none;
          height: 100%;
          border: none;
          border-radius: 0 $border-radius-1 $border-radius-1 0;
          flex: 0 0 auto;
          width: max-content;

          i {
            color: $search-color;
          }

          &:hover {
            background-color: $search-color;

            i {
              color: white;
            }
          }
        }
      }
    }

    .ngxdt-reports-button {

      .btn-download {
        background-color: $theme-blue;
        color: #fff;
        font-size: $theme-font-size;
        // margin-right: 1rem;
        border-radius: 0.3rem;
        width: auto;
        border: 1px solid transparent;
      }

      // .btn-search{
      //   background-color: transparent;
      //   color: $theme-table;
      //   font-size: $theme-font-size;
      //   border-radius: 0.30rem;
      // }
    }

    .ngxdt-select {
      display: flex;
      height: 24px;
      color: $table-data-color;
      font-size: 0.75rem;
      font-family: $font-Poppins,$font-roboto;
      flex: 1 1 auto;
      margin: auto 40px;

      label {
        margin: 0;
        align-self: center;
        margin-right: 0.25rem;
        white-space: nowrap;
      }

      select {
        height: 100%;
        width: fit-content;
        border-radius: $border-radius-1;
        padding: 0 5px;
        margin-right: 2rem;
        // outline: 1px solid $search-color;
        border: none;
        font-weight: 600;
      }

      input {
        height: 100%;
        font-size: 11px;
        width: fit-content;
        color: $theme-table;
        padding: 0 5px;
        margin: 0;
        border: none;
        font-weight: normal;
      }

      .input-activity-log {
        height: 100%;
        border-radius: $border-radius-1;
        font-size: 11px;
        width: fit-content;
        color: $theme-table;
        padding: 0 5px;
        margin-right: 2rem;
        outline: 1px solid $search-color;
        font-weight: normal;
      }
    }
  }

  .ngxdt-body {
    padding: 0.5rem 0 0 0;

    >* {
      font-size: 13px;
      letter-spacing: initial;
      color: $table-data-color;
    }
  }
}

//***/custom ngx datatable component***

//***ngx datatable***
.ngx-datatable.bootstrap {
  // variables
  $row-height: 3rem;
  $cell-padding: 0 0.75rem;
  $border-color: #f5f6fa;

  

  min-height: calc(100vh - 245px);

  .datatable-header {
    // width: 100% !important;
   // border-bottom: 2px solid $border-color;
    background-color: $border-color;
    height: 40px !important;
    .datatable-header-inner {
      font-weight: 600;
      color: #3c3d49;
      width: 100% !important;

      overflow-x: auto;

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
        opacity: 0;
        visibility: hidden;
      }
    }

    .datatable-header-cell {
      padding: 0.58rem 0.75rem;
      border: none;

      .datatable-header-cell-label {
        font-size: 13px;
        color: #131523;
      }

      // to display first column data in center
      &.make-center .datatable-header-cell-template-wrap {
        display: block;
        text-align: center;
      }
    }
  }

  .datatable-body {
    width: 100% !important;
    height: calc(100vh - 325px) !important;
    /* width */
    &::-webkit-scrollbar {
      width: 4px !important;
      height: 7px !important;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1 !important;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888 !important;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555 !important;
    }

    .datatable-scroll {
      display: inline-block;
      overflow-y: auto;
      overflow-x: auto;
      height: calc(100vh - 331px);
      width: 100% !important;

      .datatable-row-wrapper {
        min-width: 100% !important;
        width: max-content !important;
        display: table-caption !important;
      }
    }

    .empty-row {
      color: #6c757d;
      text-align: center;
    }
    

    .datatable-body-row {
      vertical-align: top;
      border-top: none;
      // border-bottom: 1px solid $border-color;
      cursor: default !important;
      background-color: #fff !important;
      min-width: 100% !important;
      height: 3rem !important;

      &.active {
        background-color: rgba(239, 239, 239, 0.4) !important;
        color: $theme-table !important;
      }

      &:hover,
      &[ng-reflect-is-selected="true"]:hover {
        background-color: rgba(239, 239, 239, 0.4) !important;
        color: $theme-table !important;
      }

      // &:hover, &.active {
      // margin: 1px 0 1px 0;
      //   background-color: $theme-blue !important;
      //   color: #fff;
      // }
      // .datatable-row-center{
      //   max-width: 100% !important;
      // }

      .datatable-body-cell {
        padding: $cell-padding;
        line-height: $row-height;
        height: 3rem !important;
        color: #131523;
        .btn_preview{
        background-color: #006eff;
          border: none;
          line-height: 1.5;
          font-size: 10px;
          padding: 3px 7px;
          margin-top: 7px;
          color: #FDFBFB;
          svg{
            padding-top: 4px;
          }
        }
        .btn_delete{
        background-color: #ff635e;
          border: none;
          line-height: 1.5;
          font-size: 10px;
          padding: 3px 7px;
          margin-top: 7px;
          color: #FDFBFB;
          svg{
            padding-top: 2px;
          }
        }
        .toggle_btn{
          display: block;
          position: relative;
          width: 100px;
          height: 40px;
          margin-top: 8px;

          input[type="checkbox"] {
            display: none; 
          }
          .toggle-button-switch {
            position: absolute;
            top: 2px;
            left: 2px;
            width: 26px;
            height: 26px;
            background-color: #fff;
            border-radius: 50%;
            cursor: pointer;
            z-index: 100;
            transition: left 0.3s;
            .img-right{
              position: absolute;
              margin: 8px 8px;

            }
          }
          .toggle-button-text {
            overflow: hidden;
            background-color:#ff635e;
            border-radius: 25px;
            // box-shadow: 2px 2px 5px 0 rgba(50, 50, 50, 0.75);
            transition: background-color 0.3s;
            font-size: 11px;
          }
          .toggle-button-text-on,
          .toggle-button-text-off {
           // float: left;
            width: 100%;
            height: 100%;
            line-height: 30px;
            font-family: $font-Poppins, sans-serif;
            font-weight: bold;
            color: #fff;
           // text-align: center;
          }
          .toggle-button-text-on{
            text-align: start;
          }
          
          .toggle-button-text-off {
            text-align: end;
          }

          input[type="checkbox"]:checked ~ .toggle-button-switch {
            left: 72px;
          }
          input[type="checkbox"]:checked ~ .toggle-button-text {
            background-color: #47c362;
          }
        }
    
        //to display column data in center
        &.make-center .datatable-body-cell-label {
          display: block;
          text-align: center;
          height: 100%;
        }
      }

      // &.datatable-row-even:not(.active) {
      //   background-color: #fff;
      // }
    }

    .datatable-group-header {
      border-bottom: 1px solid $border-color;
      height: $row-height;
      width: calc(100vw - 30px) !important;

      >* {
        height: $row-height;
      }

      .group-header-wrapper {
        display: flex;
        align-items: center;
        width: 100% !important;

        >div {
          padding: 0 0.75rem;
          line-height: $row-height;
        }

        .index-column {
          flex: 0 0 70px;
          max-width: 70px;
          text-align: center;
        }

        .group-column {
          cursor: pointer;
          flex: 0 0 270px;
        }

        .checkbox-column {
          flex: 0 0 120px;
          text-align: center;
          height: 100%;
        }
      }
    }
  }

  .datatable-footer {
    background-color: white;
    margin-bottom: 0;
    position: absolute;
    bottom: 0;

    .datatable-footer-inner {
      justify-content: end;
      display: flex !important;
      align-items: center !important;
      width: 100% !important;
      height: 35px !important;

      .page-size-control {
        position: absolute;
        left: 11px;
        color: $table-data-color;
        top: 0;
        select {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          background: none; 
          border: none;     
          padding-right: 10px;  
        }
        

        .page-size-dropdown {
          height: 22px;
          padding: 0.2rem;
          line-height: 1.5;
          border-radius: 0.2rem;
          //color: $table-data-color;
          color: #171725;
         // font-weight: 600;
          border: none;
          cursor: pointer;
          background-color: transparent;
          &:focus{
            // outline-color: $theme-blue;
            outline-color:  none !important
          }
        }
      }
      .pagination-control {
        .page-link {
          padding: 0.25rem 0.7rem;
          color: #8b8fac;
          background: transparent;
          border: 1px solid transparent;
          span {
            font-family: $font-Poppins,sans-serif;
            font-size: 15px;
            line-height: 0.9;
            vertical-align: middle;
          }
          
        }
        .page-link:focus {
          box-shadow: none !important;
        }

        .page-item.active .page-link {
          background-color: $blue-color !important;
          border-color: $blue-color !important;
          color: #fff !important;
          border: 1px solid !important;
          border-radius: 6px !important;
        }
      }
    }
  }
}
.ngx-datatable.bootstrap .datatable-footer .datatable-pager{
    margin: 12px 6px !important;
    white-space: nowrap !important;
}

.font-16 {
    font-size: 16px !important;
}

.font-12 {
    font-size: 12px !important;
}
.font-11 {
  font-size: 11px !important;
}

.font-14{
  font-size: 14px !important;
}
.font-18{
  font-size: 18px;
}
.font-17 {
  font-size: 17px !important;
}
.text-light-grey {
    color:#7E84A3  !important
}
//common search input
// search input :start
$color_inputSearch: $secondary-color;
.input_searchContainer {
//   border-bottom: 1px solid $text-light-gray;
  .searchIcon {
    padding: 0.3rem;
    svg #Path_861{
      fill: $light-grey !important;
      stroke: $light-grey !important;
    }
  }
  .search_input {
    border: none;
    color: $light-grey;
    outline: none;
    width: -webkit-fill-available;
    font-size: $font-15;
    height: 28px;
    padding-left: 2rem !important;
  }
  .search_input::placeholder {
    color: $light-grey;
  }
  .clearSearchInput {
    // display: none;
    bottom: 10px;
    right: 1%;
    cursor: pointer !important;
    svg {
      fill: $light-grey;
      stroke: $light-grey;
    }
  }
}
//// :end
.content-wrapper{
  .card{
    border-radius: 8px;
  }
}
.text-black-color{
  color: #171725 !important;
}
.cursorPointer{
  cursor: pointer !important;
}
.text-primary-color{
  color: #0058FF !important
}